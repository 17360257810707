import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Introduction from "../components/introduction"
import Spacer from "../components/spacer"


class PostTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost

    return (
      <Layout background={ true } blog={ false }>
        <SEO title={ post.title } />
        <Introduction title={ post.title } />
        <div className="row shrink-width">
          <div className="column small-12 medium-10 large-8">
           { post.acf &&
            post.acf.contnt_post &&
            post.acf.contnt_post.map((layout, i) => {
              if (layout.__typename === `WordPressAcf_text`) {
                return (
                 <div dangerouslySetInnerHTML={{ __html: layout.text }} />
                )
              }
              if (layout.__typename === `WordPressAcf_image`) {
                return (
                  <div className="gm-bottom--3 gm-top--3">
                    <img alt={ post.title } src={ layout.image.localFile.childImageSharp.original.src } />
                  </div>
                )
              }
              return null
          })}
          </div>
        </div>
        <Spacer space="4" />
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      id
      acf {
        contnt_post {
          ... on WordPressAcf_text {
            text
          }
          ... on WordPressAcf_image {
            image {
              alt_text
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`